import '@/App.less'
import { ApolloProvider } from '@apollo/client'
import { AuthenticationProvider } from '@/features/authentication/AuthenticationContextProvider'
import IAuthenticationService from '@/features/authentication/IAuthenticationService'
import MySkalHeader from '@/features/header/MySkalHeader'
import MySkalFooter from '@/features/footer/MySkalFooter'
import IHasuraService from '@/services/IHasuraService'
import { Outlet } from 'react-router-dom'
import { ErrorModalContextProvider } from './features/errorModal/ErrorModalContextProvider'
import ErrorModalContainer from './features/errorModal/ErrorModalContainer'
import * as Sentry from '@sentry/react'
import ErrorPage from '@/errors/ErrorPage'

export interface IAppProps {
  authenticationService: IAuthenticationService
  hasuraService: IHasuraService
}

function App (appProps: Readonly<IAppProps>): JSX.Element {
  return (
    <div className="App" data-testid="app-testid">
      <Sentry.ErrorBoundary fallback={<ErrorPage />}>
        <AuthenticationProvider authenticationService={appProps.authenticationService}>
          <ApolloProvider client={appProps.hasuraService.apolloClient}>
            <ErrorModalContextProvider>
              <MySkalHeader />
              <div className="app-content">
                <Outlet />
              </div>
              <MySkalFooter />
              <ErrorModalContainer/>
            </ErrorModalContextProvider>
          </ApolloProvider>
        </AuthenticationProvider>
      </Sentry.ErrorBoundary>
    </div>
  )
}

export default App
