import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType, createBrowserRouter } from 'react-router-dom'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
      React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    )
  })],
  // Sentry logs cancelled Fetch requests as errors, to prevent this we are ignoring these specific errors
  // See: https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios for more details
  ignoreErrors: [
    'TypeError: Failed to fetch',
    'TypeError: cancelled',
    'TypeError: NetworkError when attempting to fetch resource.'
  ],
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
})

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export default sentryCreateBrowserRouter
