import '@/index.less'
import sentryCreateBrowserRouter from './plugins/sentry'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  RouterProvider
} from 'react-router-dom'
import App from '@/App'
import AuthenticationService from '@/features/authentication/AuthenticationService'
import HasuraService from '@/services/HasuraService'
import { generateRoutes } from '@/router/routes'
import initI18n from './plugins/I18n'
// This file has been excluded from code coverage if more logic is added remove this exclusion from the sonar-project.properties file.

export const debouncedTime = 300
export const authenticationService = new AuthenticationService()
export const hasuraService = new HasuraService({ uri: process.env.REACT_APP_HASURA_URL ?? '' }, authenticationService)

initI18n(process.env.REACT_APP_LOCALAZY_METADATA_URL as string).catch((err) => console.error(err))

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <App authenticationService={authenticationService} hasuraService={hasuraService} />,
    children: [
      ...generateRoutes()
    ]
  }
])

authenticationService.authenticateAsync()
  .then(() => {
    const rootElement = document.getElementById('root')
    if (rootElement !== null) {
      const root = ReactDOM.createRoot(rootElement)
      root.render(
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
      )
    } else {
      throw new Error('Cannot setup router with authentication because the rootElement is null')
    }
  })
  .catch(error => alert(error))
