import '@/features/nonconformiteiten/NonConformiteiten.less'
import { useTranslation } from 'react-i18next'
import { useCurrentOperatorSkalNumber } from '@/hooks/UseCurrentOperatorSkalNumber'
import { useGetNonConformiteitenSupersetTokenLazyQuery } from '@/models'
import { LoadingOutlined } from '@ant-design/icons'
import { embedDashboard } from '@superset-ui/embedded-sdk'
import { useEffect } from 'react'

function NonConformiteitenDashboard (): JSX.Element {
  const { t } = useTranslation()
  const [currentOperatorSkalNumber] = useCurrentOperatorSkalNumber()
  const [getAccessToken, { loading, error }] = useGetNonConformiteitenSupersetTokenLazyQuery()

  const fetchGuestTokenFromBackend = async (): Promise<string> => {
    const accessToken = await getAccessToken({
      variables: {
        skalNumber: currentOperatorSkalNumber,
        dashboardId: process.env.REACT_APP_NON_CONFORMITEITEN_DASHBOARD_ID ?? ''
      }
    })
    return accessToken.data?.getNonConformiteitenSupersetToken?.accessToken ?? ''
  }

  const canLoadDashboard = (): boolean => {
    return !loading &&
      typeof error === 'undefined' &&
      currentOperatorSkalNumber !== '' &&
      process.env.REACT_APP_NON_CONFORMITEITEN_DASHBOARD_ID !== null &&
      process.env.REACT_APP_SUPERSET_URI !== null &&
      typeof process.env.REACT_APP_NON_CONFORMITEITEN_DASHBOARD_ID !== 'undefined' &&
      typeof process.env.REACT_APP_SUPERSET_URI !== 'undefined'
  }

  useEffect(() => {
    if (canLoadDashboard()) {
      void embedDashboard({
        id: process.env.REACT_APP_NON_CONFORMITEITEN_DASHBOARD_ID ?? '',
        supersetDomain: process.env.REACT_APP_SUPERSET_URI ?? '',
        mountPoint: document.getElementById('superset-container') as HTMLElement,
        fetchGuestToken: async () => await fetchGuestTokenFromBackend(),
        dashboardUiConfig: {
          hideTitle: true,
          hideChartControls: true,
          filters: {
            expanded: true
          }
        }
      })
    }
  }, [loading])

  if (loading) {
    return (<div className="message loader" data-testid="dashboard-loading"><LoadingOutlined /></div>)
  }

  return (
    <>
      <div className='dashboard-container' data-testid="dashboard">
        <h1>{t('nonconformiteiten.dashboard.title')}</h1>
      </div>
      <div id='superset-container' />
    </>
  )
}

export default NonConformiteitenDashboard
