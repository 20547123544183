import '@/features/header/navigation/MySkalNavigation.less'
import { Button, Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { MenuOutlined } from '@ant-design/icons'
import { Link, useLocation, useParams } from 'react-router-dom'
import { multiLanguageRoutes, routeKey, pathKey } from '@/router/routes'
import { getLegacyRedirectUrl } from '@/router/getLegacyRedirectUrl'

function MySkalNavigation (): JSX.Element {
  const { t, i18n } = useTranslation()
  const isInline = useMediaQuery({ maxWidth: 920 })

  const location = useLocation()
  const params = useParams()
  const basePath = Object.values(params).reduce((path, param) => path?.replace(`/${param ?? ''}`, ''), location.pathname)
  const queryParameters = new URLSearchParams(window.location.search)
  const redirectUri = queryParameters.get('redirectUri')

  const menuItems = [
    {
      key: 'notFound',
      label: <Link to={`${getLegacyRedirectUrl()}`}>{t('menu.overview')}</Link>
    },
    {
      key: 'ownCertificate',
      label: <Link to={`${process.env.REACT_APP_SKAL_BASE_URL ?? ''}${multiLanguageRoutes.ownCertificate.paths[i18n.language as pathKey]}`}>{t('menu.ownCertificate')}</Link>
    },
    {
      key: 'sharedCertificates',
      label: <Link to={`${process.env.REACT_APP_SKAL_BASE_URL ?? ''}${multiLanguageRoutes.sharedCertificates.paths[i18n.language as pathKey]}`}>{t('menu.sharedCertificates')}</Link>
    },
    {
      key: 'nonConformiteitenDashboard',
      label: <Link to={`${process.env.REACT_APP_SKAL_BASE_URL ?? ''}${multiLanguageRoutes.nonConformiteitenDashboard.paths[i18n.language as pathKey]}`}>{t('menu.nonConformiteiten')}</Link>
    }
  ]

  // Check for active menu item by redirectUri or basePath
  const activeMenuItem = menuItems.find(menuItem =>
    redirectUri !== null
      ? redirectUri?.includes(multiLanguageRoutes[menuItem.key as routeKey].paths[i18n.language as pathKey])
      : basePath === multiLanguageRoutes[menuItem.key as routeKey].paths[i18n.language as pathKey]
  )

  if (isInline) {
    return (
      <Dropdown className="menu-bar" menu={{ items: menuItems }} placement="bottomLeft">
        <Button data-testid='my-skal-navigation-inline' icon={ <MenuOutlined />} ></Button>
      </Dropdown>
    )
  }

  return (
    <Menu
      mode="horizontal"
      className="menu-bar horizontal"
      defaultSelectedKeys={[activeMenuItem?.key ?? '']}
      items={menuItems}
      data-testid="my-skal-navigation-horizontal"
    />
  )
}

export default MySkalNavigation
