/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bpchar: any;
  date: any;
  json: any;
  jsonb: any;
  timestamptz: any;
  uuid: any;
};
export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  country: Scalars['String'];
  houseNumber: Scalars['String'];
  postalCode: Scalars['String'];
  street: Scalars['String'];
};
export type Data = {
  api?: InputMaybe<Scalars['String']>;
  blobType?: InputMaybe<Scalars['String']>;
  clientRequestId?: InputMaybe<Scalars['String']>;
  contentLength?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['String']>;
  eTag?: InputMaybe<Scalars['String']>;
  requestId?: InputMaybe<Scalars['String']>;
  sequencer?: InputMaybe<Scalars['String']>;
  storageDiagnostics?: InputMaybe<StorageDiagnostics>;
  url?: InputMaybe<Scalars['String']>;
};
export type EventGridEventInput = {
  data?: InputMaybe<Data>;
  dataVersion?: InputMaybe<Scalars['String']>;
  eventTime?: InputMaybe<Scalars['String']>;
  eventType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  metadataVersion?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  topic?: InputMaybe<Scalars['String']>;
};
export type HealthOutput = {
  __typename?: 'HealthOutput';
  status?: Maybe<Scalars['String']>;
};
export type InviteOperatorCertificateOption = {
  __typename?: 'InviteOperatorCertificateOption';
  address: Address;
  name: Scalars['String'];
  operatorCertificateInquiryId: Scalars['String'];
  operatorId: Scalars['String'];
  skalNumber: Scalars['String'];
  status: Scalars['String'];
};
export type InviteOperatorOutput = {
  __typename?: 'InviteOperatorOutput';
  acceptedAt?: Maybe<Scalars['date']>;
  id: Scalars['String'];
  requestedOperatorId: Scalars['String'];
  requestingOperatorId: Scalars['String'];
  status: Scalars['String'];
};
export type RequestOperatorCertificateOption = {
  __typename?: 'RequestOperatorCertificateOption';
  address: Address;
  name: Scalars['String'];
  operatorCertificateInquiryId: Scalars['String'];
  operatorId: Scalars['String'];
  skalNumber: Scalars['String'];
  status: Scalars['String'];
};
export type StorageDiagnostics = {
  batchId?: InputMaybe<Scalars['String']>;
};
export type SuccessOutput = {
  __typename?: 'SuccessOutput';
  success?: Maybe<Scalars['Boolean']>;
};
export type SupersetAccessToken = {
  __typename?: 'SupersetAccessToken';
  accessToken: Scalars['String'];
};
export type Certificate = {
  __typename?: 'certificate';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  operator: Operator;
  operatorId: Scalars['uuid'];
  skalId: Scalars['Int'];
  tracesNumber: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  validFrom: Scalars['date'];
  validUntil: Scalars['date'];
};
export type CertificateAggregateBoolExpCount = {
  distinct?: InputMaybe<Scalars['Boolean']>;
};
export type CertificateAggregateFields = {
  __typename?: 'certificate_aggregate_fields';
  avg?: Maybe<CertificateAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CertificateMaxFields>;
  min?: Maybe<CertificateMinFields>;
  stddev?: Maybe<CertificateStddevFields>;
  stddev_pop?: Maybe<CertificateStddevPopFields>;
  stddev_samp?: Maybe<CertificateStddevSampFields>;
  sum?: Maybe<CertificateSumFields>;
  var_pop?: Maybe<CertificateVarPopFields>;
  var_samp?: Maybe<CertificateVarSampFields>;
  variance?: Maybe<CertificateVarianceFields>;
};
export type CertificateAggregateOrderBy = {
  avg?: InputMaybe<CertificateAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CertificateMaxOrderBy>;
  min?: InputMaybe<CertificateMinOrderBy>;
  stddev?: InputMaybe<CertificateStddevOrderBy>;
  stddev_pop?: InputMaybe<CertificateStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CertificateStddevSampOrderBy>;
  sum?: InputMaybe<CertificateSumOrderBy>;
  var_pop?: InputMaybe<CertificateVarPopOrderBy>;
  var_samp?: InputMaybe<CertificateVarSampOrderBy>;
  variance?: InputMaybe<CertificateVarianceOrderBy>;
};
export type CertificateAvgFields = {
  __typename?: 'certificate_avg_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type CertificateAvgOrderBy = {
  skalId?: InputMaybe<OrderBy>;
};
export type CertificateMaxFields = {
  __typename?: 'certificate_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  operatorId?: Maybe<Scalars['uuid']>;
  skalId?: Maybe<Scalars['Int']>;
  tracesNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type CertificateMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  operatorId?: InputMaybe<OrderBy>;
  skalId?: InputMaybe<OrderBy>;
  tracesNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  validFrom?: InputMaybe<OrderBy>;
  validUntil?: InputMaybe<OrderBy>;
};
export type CertificateMinFields = {
  __typename?: 'certificate_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  operatorId?: Maybe<Scalars['uuid']>;
  skalId?: Maybe<Scalars['Int']>;
  tracesNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type CertificateMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  operatorId?: InputMaybe<OrderBy>;
  skalId?: InputMaybe<OrderBy>;
  tracesNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  validFrom?: InputMaybe<OrderBy>;
  validUntil?: InputMaybe<OrderBy>;
};
export type CertificateMutationResponse = {
  __typename?: 'certificate_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Certificate>;
};
export type CertificateOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  operator?: InputMaybe<OperatorOrderBy>;
  operatorId?: InputMaybe<OrderBy>;
  skalId?: InputMaybe<OrderBy>;
  tracesNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  validFrom?: InputMaybe<OrderBy>;
  validUntil?: InputMaybe<OrderBy>;
};
export type CertificateStddevFields = {
  __typename?: 'certificate_stddev_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type CertificateStddevOrderBy = {
  skalId?: InputMaybe<OrderBy>;
};
export type CertificateStddevPopFields = {
  __typename?: 'certificate_stddev_pop_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type CertificateStddevPopOrderBy = {
  skalId?: InputMaybe<OrderBy>;
};
export type CertificateStddevSampFields = {
  __typename?: 'certificate_stddev_samp_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type CertificateStddevSampOrderBy = {
  skalId?: InputMaybe<OrderBy>;
};
export type CertificateSumFields = {
  __typename?: 'certificate_sum_fields';
  skalId?: Maybe<Scalars['Int']>;
};
export type CertificateSumOrderBy = {
  skalId?: InputMaybe<OrderBy>;
};
export type CertificateUpdates = {
};
export type CertificateVarPopFields = {
  __typename?: 'certificate_var_pop_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type CertificateVarPopOrderBy = {
  skalId?: InputMaybe<OrderBy>;
};
export type CertificateVarSampFields = {
  __typename?: 'certificate_var_samp_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type CertificateVarSampOrderBy = {
  skalId?: InputMaybe<OrderBy>;
};
export type CertificateVarianceFields = {
  __typename?: 'certificate_variance_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type CertificateVarianceOrderBy = {
  skalId?: InputMaybe<OrderBy>;
};
export type Configuration = {
  __typename?: 'configuration';
  created_at?: Maybe<Scalars['timestamptz']>;
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  value: Scalars['jsonb'];
};
export type ConfigurationAggregateFields = {
  __typename?: 'configuration_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ConfigurationMaxFields>;
  min?: Maybe<ConfigurationMinFields>;
};
export type ConfigurationMaxFields = {
  __typename?: 'configuration_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type ConfigurationMinFields = {
  __typename?: 'configuration_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type ConfigurationMutationResponse = {
  __typename?: 'configuration_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Configuration>;
};
export type ConfigurationOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};
export type ConfigurationUpdates = {
};
export enum CursorOrdering {
  Asc = 'ASC',
  Desc = 'DESC'
}
export type GetInviteOperatorCertificateOptionsOutput = {
  __typename?: 'getInviteOperatorCertificateOptionsOutput';
  count?: Maybe<Scalars['Int']>;
  inviteOperatorCertificateOptions?: Maybe<Array<Maybe<InviteOperatorCertificateOption>>>;
};
export type GetRequestOperatorCertificateOptionsOutput = {
  __typename?: 'getRequestOperatorCertificateOptionsOutput';
  count?: Maybe<Scalars['Int']>;
  requestOperatorCertificateOptions?: Maybe<Array<Maybe<RequestOperatorCertificateOption>>>;
};
export type GetTracesCertificateOutput = {
  __typename?: 'getTracesCertificateOutput';
  base64EncodedTracesCertificate: Scalars['String'];
};
export type LastSynchronization = {
  __typename?: 'last_synchronization';
  created_at: Scalars['timestamptz'];
  key: Scalars['String'];
  start_date?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};
export type LastSynchronizationAggregateFields = {
  __typename?: 'last_synchronization_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LastSynchronizationMaxFields>;
  min?: Maybe<LastSynchronizationMinFields>;
};
export type LastSynchronizationMaxFields = {
  __typename?: 'last_synchronization_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type LastSynchronizationMinFields = {
  __typename?: 'last_synchronization_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type LastSynchronizationMutationResponse = {
  __typename?: 'last_synchronization_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<LastSynchronization>;
};
export type LastSynchronizationOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  key?: InputMaybe<OrderBy>;
  start_date?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};
export type LastSynchronizationUpdates = {
};
export type Location = {
  __typename?: 'location';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  houseNumber?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isPrimary: Scalars['Boolean'];
  name: Scalars['String'];
  operator: Operator;
  operatorId: Scalars['uuid'];
  postalCode?: Maybe<Scalars['bpchar']>;
  skalId: Scalars['Int'];
  street?: Maybe<Scalars['String']>;
  tradeName?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};
export type LocationAggregateBoolExpBoolAnd = {
  distinct?: InputMaybe<Scalars['Boolean']>;
};
export type LocationAggregateBoolExpBoolOr = {
  distinct?: InputMaybe<Scalars['Boolean']>;
};
export type LocationAggregateBoolExpCount = {
  distinct?: InputMaybe<Scalars['Boolean']>;
};
export type LocationAggregateFields = {
  __typename?: 'location_aggregate_fields';
  avg?: Maybe<LocationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LocationMaxFields>;
  min?: Maybe<LocationMinFields>;
  stddev?: Maybe<LocationStddevFields>;
  stddev_pop?: Maybe<LocationStddevPopFields>;
  stddev_samp?: Maybe<LocationStddevSampFields>;
  sum?: Maybe<LocationSumFields>;
  var_pop?: Maybe<LocationVarPopFields>;
  var_samp?: Maybe<LocationVarSampFields>;
  variance?: Maybe<LocationVarianceFields>;
};
export type LocationAggregateOrderBy = {
  avg?: InputMaybe<LocationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LocationMaxOrderBy>;
  min?: InputMaybe<LocationMinOrderBy>;
  stddev?: InputMaybe<LocationStddevOrderBy>;
  stddev_pop?: InputMaybe<LocationStddevPopOrderBy>;
  stddev_samp?: InputMaybe<LocationStddevSampOrderBy>;
  sum?: InputMaybe<LocationSumOrderBy>;
  var_pop?: InputMaybe<LocationVarPopOrderBy>;
  var_samp?: InputMaybe<LocationVarSampOrderBy>;
  variance?: InputMaybe<LocationVarianceOrderBy>;
};
export type LocationAvgFields = {
  __typename?: 'location_avg_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type LocationAvgOrderBy = {
  skalId?: InputMaybe<OrderBy>;
};
export type LocationMaxFields = {
  __typename?: 'location_max_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  houseNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  operatorId?: Maybe<Scalars['uuid']>;
  postalCode?: Maybe<Scalars['bpchar']>;
  skalId?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  tradeName?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type LocationMaxOrderBy = {
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  houseNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  operatorId?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  skalId?: InputMaybe<OrderBy>;
  street?: InputMaybe<OrderBy>;
  tradeName?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};
export type LocationMinFields = {
  __typename?: 'location_min_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  houseNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  operatorId?: Maybe<Scalars['uuid']>;
  postalCode?: Maybe<Scalars['bpchar']>;
  skalId?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  tradeName?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type LocationMinOrderBy = {
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  houseNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  operatorId?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  skalId?: InputMaybe<OrderBy>;
  street?: InputMaybe<OrderBy>;
  tradeName?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};
export type LocationMutationResponse = {
  __typename?: 'location_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Location>;
};
export type LocationOrderBy = {
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  houseNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isPrimary?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  operator?: InputMaybe<OperatorOrderBy>;
  operatorId?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  skalId?: InputMaybe<OrderBy>;
  street?: InputMaybe<OrderBy>;
  tradeName?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};
export enum LocationSelectColumnLocationAggregateBoolExpBoolAndArgumentsColumns {
  IsPrimary = 'isPrimary'
}
export enum LocationSelectColumnLocationAggregateBoolExpBoolOrArgumentsColumns {
  IsPrimary = 'isPrimary'
}
export type LocationStddevFields = {
  __typename?: 'location_stddev_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type LocationStddevOrderBy = {
  skalId?: InputMaybe<OrderBy>;
};
export type LocationStddevPopFields = {
  __typename?: 'location_stddev_pop_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type LocationStddevPopOrderBy = {
  skalId?: InputMaybe<OrderBy>;
};
export type LocationStddevSampFields = {
  __typename?: 'location_stddev_samp_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type LocationStddevSampOrderBy = {
  skalId?: InputMaybe<OrderBy>;
};
export type LocationSumFields = {
  __typename?: 'location_sum_fields';
  skalId?: Maybe<Scalars['Int']>;
};
export type LocationSumOrderBy = {
  skalId?: InputMaybe<OrderBy>;
};
export type LocationUpdates = {
};
export type LocationVarPopFields = {
  __typename?: 'location_var_pop_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type LocationVarPopOrderBy = {
  skalId?: InputMaybe<OrderBy>;
};
export type LocationVarSampFields = {
  __typename?: 'location_var_samp_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type LocationVarSampOrderBy = {
  skalId?: InputMaybe<OrderBy>;
};
export type LocationVarianceFields = {
  __typename?: 'location_variance_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type LocationVarianceOrderBy = {
  skalId?: InputMaybe<OrderBy>;
};
export type MutationRoot = {
  __typename?: 'mutation_root';
  InviteOperator?: Maybe<InviteOperatorOutput>;
  delete_certificate?: Maybe<CertificateMutationResponse>;
  delete_certificate_by_pk?: Maybe<Certificate>;
  delete_configuration?: Maybe<ConfigurationMutationResponse>;
  delete_configuration_by_pk?: Maybe<Configuration>;
  delete_last_synchronization?: Maybe<LastSynchronizationMutationResponse>;
  delete_last_synchronization_by_pk?: Maybe<LastSynchronization>;
  delete_location?: Maybe<LocationMutationResponse>;
  delete_location_by_pk?: Maybe<Location>;
  delete_non_conformiteiten_dim_vestiging?: Maybe<NonConformiteitenDimVestigingMutationResponse>;
  delete_non_conformiteiten_dim_vestiging_by_pk?: Maybe<NonConformiteitenDimVestiging>;
  delete_non_conformiteiten_fct_afwijkingen?: Maybe<NonConformiteitenFctAfwijkingenMutationResponse>;
  delete_non_conformiteiten_fct_afwijkingen_by_pk?: Maybe<NonConformiteitenFctAfwijkingen>;
  delete_operator?: Maybe<OperatorMutationResponse>;
  delete_operator_by_pk?: Maybe<Operator>;
  delete_operator_certificate_inquiry?: Maybe<OperatorCertificateInquiryMutationResponse>;
  delete_operator_certificate_inquiry_by_pk?: Maybe<OperatorCertificateInquiry>;
  getTracesCertificate?: Maybe<GetTracesCertificateOutput>;
  insert_certificate?: Maybe<CertificateMutationResponse>;
  insert_certificate_one?: Maybe<Certificate>;
  insert_configuration?: Maybe<ConfigurationMutationResponse>;
  insert_configuration_one?: Maybe<Configuration>;
  insert_last_synchronization?: Maybe<LastSynchronizationMutationResponse>;
  insert_last_synchronization_one?: Maybe<LastSynchronization>;
  insert_location?: Maybe<LocationMutationResponse>;
  insert_location_one?: Maybe<Location>;
  insert_non_conformiteiten_dim_vestiging?: Maybe<NonConformiteitenDimVestigingMutationResponse>;
  insert_non_conformiteiten_dim_vestiging_one?: Maybe<NonConformiteitenDimVestiging>;
  insert_non_conformiteiten_fct_afwijkingen?: Maybe<NonConformiteitenFctAfwijkingenMutationResponse>;
  insert_non_conformiteiten_fct_afwijkingen_one?: Maybe<NonConformiteitenFctAfwijkingen>;
  insert_operator?: Maybe<OperatorMutationResponse>;
  insert_operator_certificate_inquiry?: Maybe<OperatorCertificateInquiryMutationResponse>;
  insert_operator_certificate_inquiry_one?: Maybe<OperatorCertificateInquiry>;
  insert_operator_one?: Maybe<Operator>;
  triggerNonConformiteitenSync: Scalars['uuid'];
  update_certificate?: Maybe<CertificateMutationResponse>;
  update_certificate_by_pk?: Maybe<Certificate>;
  update_certificate_many?: Maybe<Array<Maybe<CertificateMutationResponse>>>;
  update_configuration?: Maybe<ConfigurationMutationResponse>;
  update_configuration_by_pk?: Maybe<Configuration>;
  update_configuration_many?: Maybe<Array<Maybe<ConfigurationMutationResponse>>>;
  update_last_synchronization?: Maybe<LastSynchronizationMutationResponse>;
  update_last_synchronization_by_pk?: Maybe<LastSynchronization>;
  update_last_synchronization_many?: Maybe<Array<Maybe<LastSynchronizationMutationResponse>>>;
  update_location?: Maybe<LocationMutationResponse>;
  update_location_by_pk?: Maybe<Location>;
  update_location_many?: Maybe<Array<Maybe<LocationMutationResponse>>>;
  update_non_conformiteiten_dim_vestiging?: Maybe<NonConformiteitenDimVestigingMutationResponse>;
  update_non_conformiteiten_dim_vestiging_by_pk?: Maybe<NonConformiteitenDimVestiging>;
  update_non_conformiteiten_dim_vestiging_many?: Maybe<Array<Maybe<NonConformiteitenDimVestigingMutationResponse>>>;
  update_non_conformiteiten_fct_afwijkingen?: Maybe<NonConformiteitenFctAfwijkingenMutationResponse>;
  update_non_conformiteiten_fct_afwijkingen_by_pk?: Maybe<NonConformiteitenFctAfwijkingen>;
  update_non_conformiteiten_fct_afwijkingen_many?: Maybe<Array<Maybe<NonConformiteitenFctAfwijkingenMutationResponse>>>;
  update_operator?: Maybe<OperatorMutationResponse>;
  update_operator_by_pk?: Maybe<Operator>;
  update_operator_certificate_inquiry?: Maybe<OperatorCertificateInquiryMutationResponse>;
  update_operator_certificate_inquiry_by_pk?: Maybe<OperatorCertificateInquiry>;
  update_operator_certificate_inquiry_many?: Maybe<Array<Maybe<OperatorCertificateInquiryMutationResponse>>>;
  update_operator_many?: Maybe<Array<Maybe<OperatorMutationResponse>>>;
};
export type NonConformiteitenDimVestiging = {
  __typename?: 'non_conformiteiten_dim_vestiging';
  databron?: Maybe<Scalars['String']>;
  exploitant_b_key?: Maybe<Scalars['Int']>;
  exploitant_locatie?: Maybe<Scalars['String']>;
  exploitant_naam?: Maybe<Scalars['String']>;
  exploitant_skal_nummer?: Maybe<Scalars['String']>;
  gemeente?: Maybe<Scalars['String']>;
  handels_naam?: Maybe<Scalars['String']>;
  hash_code?: Maybe<Scalars['String']>;
  huidige_risico_categorie?: Maybe<Scalars['String']>;
  huisnr?: Maybe<Scalars['String']>;
  is_hoofdlocatie?: Maybe<Scalars['Boolean']>;
  is_nu_aangesloten?: Maybe<Scalars['Boolean']>;
  kvk_nummer?: Maybe<Scalars['String']>;
  kvk_vestigingnummer?: Maybe<Scalars['String']>;
  mestintermediair?: Maybe<Scalars['Boolean']>;
  plaats?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  provincie?: Maybe<Scalars['String']>;
  rvo_relatienummer?: Maybe<Scalars['String']>;
  skalnummer?: Maybe<Scalars['String']>;
  straat?: Maybe<Scalars['String']>;
  sys_end_time?: Maybe<Scalars['timestamptz']>;
  sys_start_time?: Maybe<Scalars['timestamptz']>;
  verlading_log_id?: Maybe<Scalars['Int']>;
  vestiging_b_key?: Maybe<Scalars['Int']>;
  vestiging_naam?: Maybe<Scalars['String']>;
  vestiging_naam_toevoeging?: Maybe<Scalars['String']>;
  vestiging_s_key: Scalars['Int'];
};
export type NonConformiteitenDimVestigingAggregateFields = {
  __typename?: 'non_conformiteiten_dim_vestiging_aggregate_fields';
  avg?: Maybe<NonConformiteitenDimVestigingAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<NonConformiteitenDimVestigingMaxFields>;
  min?: Maybe<NonConformiteitenDimVestigingMinFields>;
  stddev?: Maybe<NonConformiteitenDimVestigingStddevFields>;
  stddev_pop?: Maybe<NonConformiteitenDimVestigingStddevPopFields>;
  stddev_samp?: Maybe<NonConformiteitenDimVestigingStddevSampFields>;
  sum?: Maybe<NonConformiteitenDimVestigingSumFields>;
  var_pop?: Maybe<NonConformiteitenDimVestigingVarPopFields>;
  var_samp?: Maybe<NonConformiteitenDimVestigingVarSampFields>;
  variance?: Maybe<NonConformiteitenDimVestigingVarianceFields>;
};
export type NonConformiteitenDimVestigingAvgFields = {
  __typename?: 'non_conformiteiten_dim_vestiging_avg_fields';
  exploitant_b_key?: Maybe<Scalars['Float']>;
  verlading_log_id?: Maybe<Scalars['Float']>;
  vestiging_b_key?: Maybe<Scalars['Float']>;
  vestiging_s_key?: Maybe<Scalars['Float']>;
};
export type NonConformiteitenDimVestigingMaxFields = {
  __typename?: 'non_conformiteiten_dim_vestiging_max_fields';
  databron?: Maybe<Scalars['String']>;
  exploitant_b_key?: Maybe<Scalars['Int']>;
  exploitant_locatie?: Maybe<Scalars['String']>;
  exploitant_naam?: Maybe<Scalars['String']>;
  exploitant_skal_nummer?: Maybe<Scalars['String']>;
  gemeente?: Maybe<Scalars['String']>;
  handels_naam?: Maybe<Scalars['String']>;
  hash_code?: Maybe<Scalars['String']>;
  huidige_risico_categorie?: Maybe<Scalars['String']>;
  huisnr?: Maybe<Scalars['String']>;
  kvk_nummer?: Maybe<Scalars['String']>;
  kvk_vestigingnummer?: Maybe<Scalars['String']>;
  plaats?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  provincie?: Maybe<Scalars['String']>;
  rvo_relatienummer?: Maybe<Scalars['String']>;
  skalnummer?: Maybe<Scalars['String']>;
  straat?: Maybe<Scalars['String']>;
  sys_end_time?: Maybe<Scalars['timestamptz']>;
  sys_start_time?: Maybe<Scalars['timestamptz']>;
  verlading_log_id?: Maybe<Scalars['Int']>;
  vestiging_b_key?: Maybe<Scalars['Int']>;
  vestiging_naam?: Maybe<Scalars['String']>;
  vestiging_naam_toevoeging?: Maybe<Scalars['String']>;
  vestiging_s_key?: Maybe<Scalars['Int']>;
};
export type NonConformiteitenDimVestigingMinFields = {
  __typename?: 'non_conformiteiten_dim_vestiging_min_fields';
  databron?: Maybe<Scalars['String']>;
  exploitant_b_key?: Maybe<Scalars['Int']>;
  exploitant_locatie?: Maybe<Scalars['String']>;
  exploitant_naam?: Maybe<Scalars['String']>;
  exploitant_skal_nummer?: Maybe<Scalars['String']>;
  gemeente?: Maybe<Scalars['String']>;
  handels_naam?: Maybe<Scalars['String']>;
  hash_code?: Maybe<Scalars['String']>;
  huidige_risico_categorie?: Maybe<Scalars['String']>;
  huisnr?: Maybe<Scalars['String']>;
  kvk_nummer?: Maybe<Scalars['String']>;
  kvk_vestigingnummer?: Maybe<Scalars['String']>;
  plaats?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  provincie?: Maybe<Scalars['String']>;
  rvo_relatienummer?: Maybe<Scalars['String']>;
  skalnummer?: Maybe<Scalars['String']>;
  straat?: Maybe<Scalars['String']>;
  sys_end_time?: Maybe<Scalars['timestamptz']>;
  sys_start_time?: Maybe<Scalars['timestamptz']>;
  verlading_log_id?: Maybe<Scalars['Int']>;
  vestiging_b_key?: Maybe<Scalars['Int']>;
  vestiging_naam?: Maybe<Scalars['String']>;
  vestiging_naam_toevoeging?: Maybe<Scalars['String']>;
  vestiging_s_key?: Maybe<Scalars['Int']>;
};
export type NonConformiteitenDimVestigingMutationResponse = {
  __typename?: 'non_conformiteiten_dim_vestiging_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<NonConformiteitenDimVestiging>;
};
export type NonConformiteitenDimVestigingOrderBy = {
  databron?: InputMaybe<OrderBy>;
  exploitant_b_key?: InputMaybe<OrderBy>;
  exploitant_locatie?: InputMaybe<OrderBy>;
  exploitant_naam?: InputMaybe<OrderBy>;
  exploitant_skal_nummer?: InputMaybe<OrderBy>;
  gemeente?: InputMaybe<OrderBy>;
  handels_naam?: InputMaybe<OrderBy>;
  hash_code?: InputMaybe<OrderBy>;
  huidige_risico_categorie?: InputMaybe<OrderBy>;
  huisnr?: InputMaybe<OrderBy>;
  is_hoofdlocatie?: InputMaybe<OrderBy>;
  is_nu_aangesloten?: InputMaybe<OrderBy>;
  kvk_nummer?: InputMaybe<OrderBy>;
  kvk_vestigingnummer?: InputMaybe<OrderBy>;
  mestintermediair?: InputMaybe<OrderBy>;
  plaats?: InputMaybe<OrderBy>;
  postcode?: InputMaybe<OrderBy>;
  provincie?: InputMaybe<OrderBy>;
  rvo_relatienummer?: InputMaybe<OrderBy>;
  skalnummer?: InputMaybe<OrderBy>;
  straat?: InputMaybe<OrderBy>;
  sys_end_time?: InputMaybe<OrderBy>;
  sys_start_time?: InputMaybe<OrderBy>;
  verlading_log_id?: InputMaybe<OrderBy>;
  vestiging_b_key?: InputMaybe<OrderBy>;
  vestiging_naam?: InputMaybe<OrderBy>;
  vestiging_naam_toevoeging?: InputMaybe<OrderBy>;
  vestiging_s_key?: InputMaybe<OrderBy>;
};
export type NonConformiteitenDimVestigingStddevFields = {
  __typename?: 'non_conformiteiten_dim_vestiging_stddev_fields';
  exploitant_b_key?: Maybe<Scalars['Float']>;
  verlading_log_id?: Maybe<Scalars['Float']>;
  vestiging_b_key?: Maybe<Scalars['Float']>;
  vestiging_s_key?: Maybe<Scalars['Float']>;
};
export type NonConformiteitenDimVestigingStddevPopFields = {
  __typename?: 'non_conformiteiten_dim_vestiging_stddev_pop_fields';
  exploitant_b_key?: Maybe<Scalars['Float']>;
  verlading_log_id?: Maybe<Scalars['Float']>;
  vestiging_b_key?: Maybe<Scalars['Float']>;
  vestiging_s_key?: Maybe<Scalars['Float']>;
};
export type NonConformiteitenDimVestigingStddevSampFields = {
  __typename?: 'non_conformiteiten_dim_vestiging_stddev_samp_fields';
  exploitant_b_key?: Maybe<Scalars['Float']>;
  verlading_log_id?: Maybe<Scalars['Float']>;
  vestiging_b_key?: Maybe<Scalars['Float']>;
  vestiging_s_key?: Maybe<Scalars['Float']>;
};
export type NonConformiteitenDimVestigingSumFields = {
  __typename?: 'non_conformiteiten_dim_vestiging_sum_fields';
  exploitant_b_key?: Maybe<Scalars['Int']>;
  verlading_log_id?: Maybe<Scalars['Int']>;
  vestiging_b_key?: Maybe<Scalars['Int']>;
  vestiging_s_key?: Maybe<Scalars['Int']>;
};
export type NonConformiteitenDimVestigingUpdates = {
};
export type NonConformiteitenDimVestigingVarPopFields = {
  __typename?: 'non_conformiteiten_dim_vestiging_var_pop_fields';
  exploitant_b_key?: Maybe<Scalars['Float']>;
  verlading_log_id?: Maybe<Scalars['Float']>;
  vestiging_b_key?: Maybe<Scalars['Float']>;
  vestiging_s_key?: Maybe<Scalars['Float']>;
};
export type NonConformiteitenDimVestigingVarSampFields = {
  __typename?: 'non_conformiteiten_dim_vestiging_var_samp_fields';
  exploitant_b_key?: Maybe<Scalars['Float']>;
  verlading_log_id?: Maybe<Scalars['Float']>;
  vestiging_b_key?: Maybe<Scalars['Float']>;
  vestiging_s_key?: Maybe<Scalars['Float']>;
};
export type NonConformiteitenDimVestigingVarianceFields = {
  __typename?: 'non_conformiteiten_dim_vestiging_variance_fields';
  exploitant_b_key?: Maybe<Scalars['Float']>;
  verlading_log_id?: Maybe<Scalars['Float']>;
  vestiging_b_key?: Maybe<Scalars['Float']>;
  vestiging_s_key?: Maybe<Scalars['Float']>;
};
export type NonConformiteitenFctAfwijkingen = {
  __typename?: 'non_conformiteiten_fct_afwijkingen';
  aantal?: Maybe<Scalars['Int']>;
  afwijking_datum_s_key?: Maybe<Scalars['Int']>;
  afwijking_flow?: Maybe<Scalars['String']>;
  afwijkingen_s_key: Scalars['Int'];
  antwoord?: Maybe<Scalars['String']>;
  antwoord_sid?: Maybe<Scalars['Int']>;
  constatering_datum?: Maybe<Scalars['date']>;
  databron?: Maybe<Scalars['String']>;
  discipline_s_key?: Maybe<Scalars['Int']>;
  exploitant_nummer?: Maybe<Scalars['String']>;
  gebruiker?: Maybe<Scalars['String']>;
  hash_code?: Maybe<Scalars['String']>;
  herhaalde_afwijking?: Maybe<Scalars['String']>;
  herstelvastgesteld_datum?: Maybe<Scalars['date']>;
  inspecteur?: Maybe<Scalars['String']>;
  inspectietype?: Maybe<Scalars['String']>;
  is_geconstateerd?: Maybe<Scalars['Boolean']>;
  is_gesloten?: Maybe<Scalars['Boolean']>;
  orig_sid?: Maybe<Scalars['Int']>;
  resultaat?: Maybe<Scalars['String']>;
  sys_end_time?: Maybe<Scalars['timestamptz']>;
  sys_start_time?: Maybe<Scalars['timestamptz']>;
  uiterlijke_herstel_datum?: Maybe<Scalars['date']>;
  verlading_log_id?: Maybe<Scalars['Int']>;
  vestiging_s_key?: Maybe<Scalars['Int']>;
  voorstel?: Maybe<Scalars['String']>;
  vraag?: Maybe<Scalars['String']>;
  vraag_sid?: Maybe<Scalars['Int']>;
};
export type NonConformiteitenFctAfwijkingenAggregateFields = {
  __typename?: 'non_conformiteiten_fct_afwijkingen_aggregate_fields';
  avg?: Maybe<NonConformiteitenFctAfwijkingenAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<NonConformiteitenFctAfwijkingenMaxFields>;
  min?: Maybe<NonConformiteitenFctAfwijkingenMinFields>;
  stddev?: Maybe<NonConformiteitenFctAfwijkingenStddevFields>;
  stddev_pop?: Maybe<NonConformiteitenFctAfwijkingenStddevPopFields>;
  stddev_samp?: Maybe<NonConformiteitenFctAfwijkingenStddevSampFields>;
  sum?: Maybe<NonConformiteitenFctAfwijkingenSumFields>;
  var_pop?: Maybe<NonConformiteitenFctAfwijkingenVarPopFields>;
  var_samp?: Maybe<NonConformiteitenFctAfwijkingenVarSampFields>;
  variance?: Maybe<NonConformiteitenFctAfwijkingenVarianceFields>;
};
export type NonConformiteitenFctAfwijkingenAvgFields = {
  __typename?: 'non_conformiteiten_fct_afwijkingen_avg_fields';
  aantal?: Maybe<Scalars['Float']>;
  afwijking_datum_s_key?: Maybe<Scalars['Float']>;
  afwijkingen_s_key?: Maybe<Scalars['Float']>;
  antwoord_sid?: Maybe<Scalars['Float']>;
  discipline_s_key?: Maybe<Scalars['Float']>;
  orig_sid?: Maybe<Scalars['Float']>;
  verlading_log_id?: Maybe<Scalars['Float']>;
  vestiging_s_key?: Maybe<Scalars['Float']>;
  vraag_sid?: Maybe<Scalars['Float']>;
};
export type NonConformiteitenFctAfwijkingenMaxFields = {
  __typename?: 'non_conformiteiten_fct_afwijkingen_max_fields';
  aantal?: Maybe<Scalars['Int']>;
  afwijking_datum_s_key?: Maybe<Scalars['Int']>;
  afwijking_flow?: Maybe<Scalars['String']>;
  afwijkingen_s_key?: Maybe<Scalars['Int']>;
  antwoord?: Maybe<Scalars['String']>;
  antwoord_sid?: Maybe<Scalars['Int']>;
  constatering_datum?: Maybe<Scalars['date']>;
  databron?: Maybe<Scalars['String']>;
  discipline_s_key?: Maybe<Scalars['Int']>;
  exploitant_nummer?: Maybe<Scalars['String']>;
  gebruiker?: Maybe<Scalars['String']>;
  hash_code?: Maybe<Scalars['String']>;
  herhaalde_afwijking?: Maybe<Scalars['String']>;
  herstelvastgesteld_datum?: Maybe<Scalars['date']>;
  inspecteur?: Maybe<Scalars['String']>;
  inspectietype?: Maybe<Scalars['String']>;
  orig_sid?: Maybe<Scalars['Int']>;
  resultaat?: Maybe<Scalars['String']>;
  sys_end_time?: Maybe<Scalars['timestamptz']>;
  sys_start_time?: Maybe<Scalars['timestamptz']>;
  uiterlijke_herstel_datum?: Maybe<Scalars['date']>;
  verlading_log_id?: Maybe<Scalars['Int']>;
  vestiging_s_key?: Maybe<Scalars['Int']>;
  voorstel?: Maybe<Scalars['String']>;
  vraag?: Maybe<Scalars['String']>;
  vraag_sid?: Maybe<Scalars['Int']>;
};
export type NonConformiteitenFctAfwijkingenMinFields = {
  __typename?: 'non_conformiteiten_fct_afwijkingen_min_fields';
  aantal?: Maybe<Scalars['Int']>;
  afwijking_datum_s_key?: Maybe<Scalars['Int']>;
  afwijking_flow?: Maybe<Scalars['String']>;
  afwijkingen_s_key?: Maybe<Scalars['Int']>;
  antwoord?: Maybe<Scalars['String']>;
  antwoord_sid?: Maybe<Scalars['Int']>;
  constatering_datum?: Maybe<Scalars['date']>;
  databron?: Maybe<Scalars['String']>;
  discipline_s_key?: Maybe<Scalars['Int']>;
  exploitant_nummer?: Maybe<Scalars['String']>;
  gebruiker?: Maybe<Scalars['String']>;
  hash_code?: Maybe<Scalars['String']>;
  herhaalde_afwijking?: Maybe<Scalars['String']>;
  herstelvastgesteld_datum?: Maybe<Scalars['date']>;
  inspecteur?: Maybe<Scalars['String']>;
  inspectietype?: Maybe<Scalars['String']>;
  orig_sid?: Maybe<Scalars['Int']>;
  resultaat?: Maybe<Scalars['String']>;
  sys_end_time?: Maybe<Scalars['timestamptz']>;
  sys_start_time?: Maybe<Scalars['timestamptz']>;
  uiterlijke_herstel_datum?: Maybe<Scalars['date']>;
  verlading_log_id?: Maybe<Scalars['Int']>;
  vestiging_s_key?: Maybe<Scalars['Int']>;
  voorstel?: Maybe<Scalars['String']>;
  vraag?: Maybe<Scalars['String']>;
  vraag_sid?: Maybe<Scalars['Int']>;
};
export type NonConformiteitenFctAfwijkingenMutationResponse = {
  __typename?: 'non_conformiteiten_fct_afwijkingen_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<NonConformiteitenFctAfwijkingen>;
};
export type NonConformiteitenFctAfwijkingenOrderBy = {
  aantal?: InputMaybe<OrderBy>;
  afwijking_datum_s_key?: InputMaybe<OrderBy>;
  afwijking_flow?: InputMaybe<OrderBy>;
  afwijkingen_s_key?: InputMaybe<OrderBy>;
  antwoord?: InputMaybe<OrderBy>;
  antwoord_sid?: InputMaybe<OrderBy>;
  constatering_datum?: InputMaybe<OrderBy>;
  databron?: InputMaybe<OrderBy>;
  discipline_s_key?: InputMaybe<OrderBy>;
  exploitant_nummer?: InputMaybe<OrderBy>;
  gebruiker?: InputMaybe<OrderBy>;
  hash_code?: InputMaybe<OrderBy>;
  herhaalde_afwijking?: InputMaybe<OrderBy>;
  herstelvastgesteld_datum?: InputMaybe<OrderBy>;
  inspecteur?: InputMaybe<OrderBy>;
  inspectietype?: InputMaybe<OrderBy>;
  is_geconstateerd?: InputMaybe<OrderBy>;
  is_gesloten?: InputMaybe<OrderBy>;
  orig_sid?: InputMaybe<OrderBy>;
  resultaat?: InputMaybe<OrderBy>;
  sys_end_time?: InputMaybe<OrderBy>;
  sys_start_time?: InputMaybe<OrderBy>;
  uiterlijke_herstel_datum?: InputMaybe<OrderBy>;
  verlading_log_id?: InputMaybe<OrderBy>;
  vestiging_s_key?: InputMaybe<OrderBy>;
  voorstel?: InputMaybe<OrderBy>;
  vraag?: InputMaybe<OrderBy>;
  vraag_sid?: InputMaybe<OrderBy>;
};
export type NonConformiteitenFctAfwijkingenStddevFields = {
  __typename?: 'non_conformiteiten_fct_afwijkingen_stddev_fields';
  aantal?: Maybe<Scalars['Float']>;
  afwijking_datum_s_key?: Maybe<Scalars['Float']>;
  afwijkingen_s_key?: Maybe<Scalars['Float']>;
  antwoord_sid?: Maybe<Scalars['Float']>;
  discipline_s_key?: Maybe<Scalars['Float']>;
  orig_sid?: Maybe<Scalars['Float']>;
  verlading_log_id?: Maybe<Scalars['Float']>;
  vestiging_s_key?: Maybe<Scalars['Float']>;
  vraag_sid?: Maybe<Scalars['Float']>;
};
export type NonConformiteitenFctAfwijkingenStddevPopFields = {
  __typename?: 'non_conformiteiten_fct_afwijkingen_stddev_pop_fields';
  aantal?: Maybe<Scalars['Float']>;
  afwijking_datum_s_key?: Maybe<Scalars['Float']>;
  afwijkingen_s_key?: Maybe<Scalars['Float']>;
  antwoord_sid?: Maybe<Scalars['Float']>;
  discipline_s_key?: Maybe<Scalars['Float']>;
  orig_sid?: Maybe<Scalars['Float']>;
  verlading_log_id?: Maybe<Scalars['Float']>;
  vestiging_s_key?: Maybe<Scalars['Float']>;
  vraag_sid?: Maybe<Scalars['Float']>;
};
export type NonConformiteitenFctAfwijkingenStddevSampFields = {
  __typename?: 'non_conformiteiten_fct_afwijkingen_stddev_samp_fields';
  aantal?: Maybe<Scalars['Float']>;
  afwijking_datum_s_key?: Maybe<Scalars['Float']>;
  afwijkingen_s_key?: Maybe<Scalars['Float']>;
  antwoord_sid?: Maybe<Scalars['Float']>;
  discipline_s_key?: Maybe<Scalars['Float']>;
  orig_sid?: Maybe<Scalars['Float']>;
  verlading_log_id?: Maybe<Scalars['Float']>;
  vestiging_s_key?: Maybe<Scalars['Float']>;
  vraag_sid?: Maybe<Scalars['Float']>;
};
export type NonConformiteitenFctAfwijkingenSumFields = {
  __typename?: 'non_conformiteiten_fct_afwijkingen_sum_fields';
  aantal?: Maybe<Scalars['Int']>;
  afwijking_datum_s_key?: Maybe<Scalars['Int']>;
  afwijkingen_s_key?: Maybe<Scalars['Int']>;
  antwoord_sid?: Maybe<Scalars['Int']>;
  discipline_s_key?: Maybe<Scalars['Int']>;
  orig_sid?: Maybe<Scalars['Int']>;
  verlading_log_id?: Maybe<Scalars['Int']>;
  vestiging_s_key?: Maybe<Scalars['Int']>;
  vraag_sid?: Maybe<Scalars['Int']>;
};
export type NonConformiteitenFctAfwijkingenUpdates = {
};
export type NonConformiteitenFctAfwijkingenVarPopFields = {
  __typename?: 'non_conformiteiten_fct_afwijkingen_var_pop_fields';
  aantal?: Maybe<Scalars['Float']>;
  afwijking_datum_s_key?: Maybe<Scalars['Float']>;
  afwijkingen_s_key?: Maybe<Scalars['Float']>;
  antwoord_sid?: Maybe<Scalars['Float']>;
  discipline_s_key?: Maybe<Scalars['Float']>;
  orig_sid?: Maybe<Scalars['Float']>;
  verlading_log_id?: Maybe<Scalars['Float']>;
  vestiging_s_key?: Maybe<Scalars['Float']>;
  vraag_sid?: Maybe<Scalars['Float']>;
};
export type NonConformiteitenFctAfwijkingenVarSampFields = {
  __typename?: 'non_conformiteiten_fct_afwijkingen_var_samp_fields';
  aantal?: Maybe<Scalars['Float']>;
  afwijking_datum_s_key?: Maybe<Scalars['Float']>;
  afwijkingen_s_key?: Maybe<Scalars['Float']>;
  antwoord_sid?: Maybe<Scalars['Float']>;
  discipline_s_key?: Maybe<Scalars['Float']>;
  orig_sid?: Maybe<Scalars['Float']>;
  verlading_log_id?: Maybe<Scalars['Float']>;
  vestiging_s_key?: Maybe<Scalars['Float']>;
  vraag_sid?: Maybe<Scalars['Float']>;
};
export type NonConformiteitenFctAfwijkingenVarianceFields = {
  __typename?: 'non_conformiteiten_fct_afwijkingen_variance_fields';
  aantal?: Maybe<Scalars['Float']>;
  afwijking_datum_s_key?: Maybe<Scalars['Float']>;
  afwijkingen_s_key?: Maybe<Scalars['Float']>;
  antwoord_sid?: Maybe<Scalars['Float']>;
  discipline_s_key?: Maybe<Scalars['Float']>;
  orig_sid?: Maybe<Scalars['Float']>;
  verlading_log_id?: Maybe<Scalars['Float']>;
  vestiging_s_key?: Maybe<Scalars['Float']>;
  vraag_sid?: Maybe<Scalars['Float']>;
};
export type Operator = {
  __typename?: 'operator';
  certificates: Array<Certificate>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  locations: Array<Location>;
  primary_contact_email_address?: Maybe<Scalars['String']>;
  requested_certificate_inquiries: Array<OperatorCertificateInquiry>;
  requesting_certificate_inquiries: Array<OperatorCertificateInquiry>;
  skalId: Scalars['Int'];
  skalNumber: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};
export type OperatorAggregateFields = {
  __typename?: 'operator_aggregate_fields';
  avg?: Maybe<OperatorAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OperatorMaxFields>;
  min?: Maybe<OperatorMinFields>;
  stddev?: Maybe<OperatorStddevFields>;
  stddev_pop?: Maybe<OperatorStddevPopFields>;
  stddev_samp?: Maybe<OperatorStddevSampFields>;
  sum?: Maybe<OperatorSumFields>;
  var_pop?: Maybe<OperatorVarPopFields>;
  var_samp?: Maybe<OperatorVarSampFields>;
  variance?: Maybe<OperatorVarianceFields>;
};
export type OperatorAvgFields = {
  __typename?: 'operator_avg_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type OperatorCertificateInquiry = {
  __typename?: 'operator_certificate_inquiry';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  requested_operator: Operator;
  requested_operator_id: Scalars['uuid'];
  requesting_operator: Operator;
  requesting_operator_id: Scalars['uuid'];
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};
export type OperatorCertificateInquiryAggregateBoolExpCount = {
  distinct?: InputMaybe<Scalars['Boolean']>;
};
export type OperatorCertificateInquiryAggregateFields = {
  __typename?: 'operator_certificate_inquiry_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<OperatorCertificateInquiryMaxFields>;
  min?: Maybe<OperatorCertificateInquiryMinFields>;
};
export type OperatorCertificateInquiryAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OperatorCertificateInquiryMaxOrderBy>;
  min?: InputMaybe<OperatorCertificateInquiryMinOrderBy>;
};
export type OperatorCertificateInquiryMaxFields = {
  __typename?: 'operator_certificate_inquiry_max_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  requested_operator_id?: Maybe<Scalars['uuid']>;
  requesting_operator_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type OperatorCertificateInquiryMaxOrderBy = {
  accepted_at?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  requested_operator_id?: InputMaybe<OrderBy>;
  requesting_operator_id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};
export type OperatorCertificateInquiryMinFields = {
  __typename?: 'operator_certificate_inquiry_min_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  requested_operator_id?: Maybe<Scalars['uuid']>;
  requesting_operator_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type OperatorCertificateInquiryMinOrderBy = {
  accepted_at?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  requested_operator_id?: InputMaybe<OrderBy>;
  requesting_operator_id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};
export type OperatorCertificateInquiryMutationResponse = {
  __typename?: 'operator_certificate_inquiry_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<OperatorCertificateInquiry>;
};
export type OperatorCertificateInquiryOrderBy = {
  accepted_at?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  requested_operator?: InputMaybe<OperatorOrderBy>;
  requested_operator_id?: InputMaybe<OrderBy>;
  requesting_operator?: InputMaybe<OperatorOrderBy>;
  requesting_operator_id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};
export type OperatorCertificateInquiryUpdates = {
};
export type OperatorMaxFields = {
  __typename?: 'operator_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  primary_contact_email_address?: Maybe<Scalars['String']>;
  skalId?: Maybe<Scalars['Int']>;
  skalNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type OperatorMinFields = {
  __typename?: 'operator_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  primary_contact_email_address?: Maybe<Scalars['String']>;
  skalId?: Maybe<Scalars['Int']>;
  skalNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type OperatorMutationResponse = {
  __typename?: 'operator_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Operator>;
};
export type OperatorOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  primary_contact_email_address?: InputMaybe<OrderBy>;
  skalId?: InputMaybe<OrderBy>;
  skalNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};
export type OperatorSharedCertificate = {
  __typename?: 'operator_shared_certificate';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  certificate_number?: Maybe<Scalars['String']>;
  operator_certificate_inquiry_id?: Maybe<Scalars['uuid']>;
  requested_operator_id?: Maybe<Scalars['uuid']>;
  requested_operator_location_city?: Maybe<Scalars['String']>;
  requested_operator_location_country?: Maybe<Scalars['String']>;
  requested_operator_location_house_number?: Maybe<Scalars['String']>;
  requested_operator_location_is_primary?: Maybe<Scalars['Boolean']>;
  requested_operator_location_name?: Maybe<Scalars['String']>;
  requested_operator_location_postal_code?: Maybe<Scalars['bpchar']>;
  requested_operator_location_street?: Maybe<Scalars['String']>;
  requested_operator_location_trade_name?: Maybe<Scalars['String']>;
  requested_operator_skal_number?: Maybe<Scalars['String']>;
  requesting_operator_id?: Maybe<Scalars['uuid']>;
  requesting_operator_location_city?: Maybe<Scalars['String']>;
  requesting_operator_location_country?: Maybe<Scalars['String']>;
  requesting_operator_location_house_number?: Maybe<Scalars['String']>;
  requesting_operator_location_is_primary?: Maybe<Scalars['Boolean']>;
  requesting_operator_location_name?: Maybe<Scalars['String']>;
  requesting_operator_location_postal_code?: Maybe<Scalars['bpchar']>;
  requesting_operator_location_street?: Maybe<Scalars['String']>;
  requesting_operator_location_trade_name?: Maybe<Scalars['String']>;
  requesting_operator_skal_number?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  valid_from?: Maybe<Scalars['date']>;
  valid_until?: Maybe<Scalars['date']>;
};
export type OperatorSharedCertificateAggregateFields = {
  __typename?: 'operator_shared_certificate_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<OperatorSharedCertificateMaxFields>;
  min?: Maybe<OperatorSharedCertificateMinFields>;
};
export type OperatorSharedCertificateMaxFields = {
  __typename?: 'operator_shared_certificate_max_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  certificate_number?: Maybe<Scalars['String']>;
  operator_certificate_inquiry_id?: Maybe<Scalars['uuid']>;
  requested_operator_id?: Maybe<Scalars['uuid']>;
  requested_operator_location_city?: Maybe<Scalars['String']>;
  requested_operator_location_country?: Maybe<Scalars['String']>;
  requested_operator_location_house_number?: Maybe<Scalars['String']>;
  requested_operator_location_name?: Maybe<Scalars['String']>;
  requested_operator_location_postal_code?: Maybe<Scalars['bpchar']>;
  requested_operator_location_street?: Maybe<Scalars['String']>;
  requested_operator_location_trade_name?: Maybe<Scalars['String']>;
  requested_operator_skal_number?: Maybe<Scalars['String']>;
  requesting_operator_id?: Maybe<Scalars['uuid']>;
  requesting_operator_location_city?: Maybe<Scalars['String']>;
  requesting_operator_location_country?: Maybe<Scalars['String']>;
  requesting_operator_location_house_number?: Maybe<Scalars['String']>;
  requesting_operator_location_name?: Maybe<Scalars['String']>;
  requesting_operator_location_postal_code?: Maybe<Scalars['bpchar']>;
  requesting_operator_location_street?: Maybe<Scalars['String']>;
  requesting_operator_location_trade_name?: Maybe<Scalars['String']>;
  requesting_operator_skal_number?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  valid_from?: Maybe<Scalars['date']>;
  valid_until?: Maybe<Scalars['date']>;
};
export type OperatorSharedCertificateMinFields = {
  __typename?: 'operator_shared_certificate_min_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  certificate_number?: Maybe<Scalars['String']>;
  operator_certificate_inquiry_id?: Maybe<Scalars['uuid']>;
  requested_operator_id?: Maybe<Scalars['uuid']>;
  requested_operator_location_city?: Maybe<Scalars['String']>;
  requested_operator_location_country?: Maybe<Scalars['String']>;
  requested_operator_location_house_number?: Maybe<Scalars['String']>;
  requested_operator_location_name?: Maybe<Scalars['String']>;
  requested_operator_location_postal_code?: Maybe<Scalars['bpchar']>;
  requested_operator_location_street?: Maybe<Scalars['String']>;
  requested_operator_location_trade_name?: Maybe<Scalars['String']>;
  requested_operator_skal_number?: Maybe<Scalars['String']>;
  requesting_operator_id?: Maybe<Scalars['uuid']>;
  requesting_operator_location_city?: Maybe<Scalars['String']>;
  requesting_operator_location_country?: Maybe<Scalars['String']>;
  requesting_operator_location_house_number?: Maybe<Scalars['String']>;
  requesting_operator_location_name?: Maybe<Scalars['String']>;
  requesting_operator_location_postal_code?: Maybe<Scalars['bpchar']>;
  requesting_operator_location_street?: Maybe<Scalars['String']>;
  requesting_operator_location_trade_name?: Maybe<Scalars['String']>;
  requesting_operator_skal_number?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  valid_from?: Maybe<Scalars['date']>;
  valid_until?: Maybe<Scalars['date']>;
};
export type OperatorSharedCertificateOrderBy = {
  accepted_at?: InputMaybe<OrderBy>;
  certificate_number?: InputMaybe<OrderBy>;
  operator_certificate_inquiry_id?: InputMaybe<OrderBy>;
  requested_operator_id?: InputMaybe<OrderBy>;
  requested_operator_location_city?: InputMaybe<OrderBy>;
  requested_operator_location_country?: InputMaybe<OrderBy>;
  requested_operator_location_house_number?: InputMaybe<OrderBy>;
  requested_operator_location_is_primary?: InputMaybe<OrderBy>;
  requested_operator_location_name?: InputMaybe<OrderBy>;
  requested_operator_location_postal_code?: InputMaybe<OrderBy>;
  requested_operator_location_street?: InputMaybe<OrderBy>;
  requested_operator_location_trade_name?: InputMaybe<OrderBy>;
  requested_operator_skal_number?: InputMaybe<OrderBy>;
  requesting_operator_id?: InputMaybe<OrderBy>;
  requesting_operator_location_city?: InputMaybe<OrderBy>;
  requesting_operator_location_country?: InputMaybe<OrderBy>;
  requesting_operator_location_house_number?: InputMaybe<OrderBy>;
  requesting_operator_location_is_primary?: InputMaybe<OrderBy>;
  requesting_operator_location_name?: InputMaybe<OrderBy>;
  requesting_operator_location_postal_code?: InputMaybe<OrderBy>;
  requesting_operator_location_street?: InputMaybe<OrderBy>;
  requesting_operator_location_trade_name?: InputMaybe<OrderBy>;
  requesting_operator_skal_number?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  valid_from?: InputMaybe<OrderBy>;
  valid_until?: InputMaybe<OrderBy>;
};
export type OperatorStddevFields = {
  __typename?: 'operator_stddev_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type OperatorStddevPopFields = {
  __typename?: 'operator_stddev_pop_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type OperatorStddevSampFields = {
  __typename?: 'operator_stddev_samp_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type OperatorSumFields = {
  __typename?: 'operator_sum_fields';
  skalId?: Maybe<Scalars['Int']>;
};
export type OperatorUpdates = {
};
export type OperatorVarPopFields = {
  __typename?: 'operator_var_pop_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type OperatorVarSampFields = {
  __typename?: 'operator_var_samp_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export type OperatorVarianceFields = {
  __typename?: 'operator_variance_fields';
  skalId?: Maybe<Scalars['Float']>;
};
export enum OrderBy {
  Asc = 'asc',
  AscNullsFirst = 'asc_nulls_first',
  AscNullsLast = 'asc_nulls_last',
  Desc = 'desc',
  DescNullsFirst = 'desc_nulls_first',
  DescNullsLast = 'desc_nulls_last'
}
export type QueryRoot = {
  __typename?: 'query_root';
  certificate: Array<Certificate>;
  certificate_by_pk?: Maybe<Certificate>;
  configuration: Array<Configuration>;
  configuration_by_pk?: Maybe<Configuration>;
  getInviteOperatorCertificateOptions?: Maybe<GetInviteOperatorCertificateOptionsOutput>;
  getNonConformiteitenSupersetToken?: Maybe<SupersetAccessToken>;
  getRequestOperatorCertificateOptions?: Maybe<GetRequestOperatorCertificateOptionsOutput>;
  health?: Maybe<HealthOutput>;
  last_synchronization: Array<LastSynchronization>;
  last_synchronization_by_pk?: Maybe<LastSynchronization>;
  location: Array<Location>;
  location_by_pk?: Maybe<Location>;
  non_conformiteiten_dim_vestiging: Array<NonConformiteitenDimVestiging>;
  non_conformiteiten_dim_vestiging_by_pk?: Maybe<NonConformiteitenDimVestiging>;
  non_conformiteiten_fct_afwijkingen: Array<NonConformiteitenFctAfwijkingen>;
  non_conformiteiten_fct_afwijkingen_by_pk?: Maybe<NonConformiteitenFctAfwijkingen>;
  operator: Array<Operator>;
  operator_by_pk?: Maybe<Operator>;
  operator_certificate_inquiry: Array<OperatorCertificateInquiry>;
  operator_certificate_inquiry_by_pk?: Maybe<OperatorCertificateInquiry>;
  operator_shared_certificate: Array<OperatorSharedCertificate>;
  triggerNonConformiteitenSync?: Maybe<TriggerNonConformiteitenSync>;
};
export type TriggerNonConformiteitenSync = {
  __typename?: 'triggerNonConformiteitenSync';
  created_at: Scalars['timestamptz'];
  errors?: Maybe<Scalars['json']>;
  id: Scalars['uuid'];
  output?: Maybe<SuccessOutput>;
};
export type GetTracesCertificateMutationVariables = Exact<{
  certificateId: Scalars['String'];
}>;
export type GetTracesCertificateMutation = { __typename?: 'mutation_root', getTracesCertificate?: { __typename?: 'getTracesCertificateOutput', base64EncodedTracesCertificate: string } | null | undefined };
export type AcceptCertificateInquiryMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;
export type AcceptCertificateInquiryMutation = { __typename?: 'mutation_root', update_operator_certificate_inquiry?: { __typename?: 'operator_certificate_inquiry_mutation_response', returning: Array<{ __typename?: 'operator_certificate_inquiry', id: any }> } | null | undefined };
export type InviteOperatorMutationVariables = Exact<{
  requestedOperatorId: Scalars['String'];
  requestingOperatorId: Scalars['String'];
}>;
export type InviteOperatorMutation = { __typename?: 'mutation_root', InviteOperator?: { __typename?: 'InviteOperatorOutput', acceptedAt?: any | null | undefined, id: string, requestedOperatorId: string, requestingOperatorId: string, status: string } | null | undefined };
export type RemoveCertificateInquiryMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;
export type RemoveCertificateInquiryMutation = { __typename?: 'mutation_root', delete_operator_certificate_inquiry_by_pk?: { __typename?: 'operator_certificate_inquiry', id: any } | null | undefined };
export type ShareCertificateMutationVariables = Exact<{
  requestedOperatorId: Scalars['uuid'];
  requestingOperatorId: Scalars['uuid'];
}>;
export type ShareCertificateMutation = { __typename?: 'mutation_root', insert_operator_certificate_inquiry?: { __typename?: 'operator_certificate_inquiry_mutation_response', affected_rows: number } | null | undefined };
export type GetInviteOperatorCertificateOptionsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  search: Scalars['String'];
  sort: Scalars['String'];
  requestedOperatorId: Scalars['String'];
}>;
export type GetInviteOperatorCertificateOptionsQuery = { __typename?: 'query_root', getInviteOperatorCertificateOptions?: { __typename?: 'getInviteOperatorCertificateOptionsOutput', count?: number | null | undefined, inviteOperatorCertificateOptions?: Array<{ __typename?: 'InviteOperatorCertificateOption', name: string, operatorCertificateInquiryId: string, skalNumber: string, operatorId: string, status: string, address: { __typename?: 'Address', city: string, country: string, houseNumber: string, postalCode: string, street: string } } | null | undefined> | null | undefined } | null | undefined };
export type GetPendingRequestedCertificateCountQueryVariables = Exact<{
  requested_operator_id: Scalars['uuid'];
}>;
export type GetPendingRequestedCertificateCountQuery = { __typename?: 'query_root', operator_certificate_inquiry_aggregate: { __typename?: 'operator_certificate_inquiry_aggregate', aggregate?: { __typename?: 'operator_certificate_inquiry_aggregate_fields', count: number } | null | undefined } };
export type GetRequestedCertificatesQueryVariables = Exact<{
  requested_operator_id: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
  order_by_name?: InputMaybe<OrderBy>;
  order_by_skalNumber?: InputMaybe<OrderBy>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
}>;
export type GetRequestedCertificatesQuery = { __typename?: 'query_root', operator_shared_certificate: Array<{ __typename?: 'operator_shared_certificate', operator_certificate_inquiry_id?: any | null | undefined, requesting_operator_location_name?: string | null | undefined, requesting_operator_location_city?: string | null | undefined, requesting_operator_location_postal_code?: any | null | undefined, requesting_operator_location_street?: string | null | undefined, requesting_operator_location_house_number?: string | null | undefined, requesting_operator_skal_number?: string | null | undefined, valid_until?: any | null | undefined, certificate_number?: string | null | undefined, requesting_operator_id?: any | null | undefined, requested_operator_id?: any | null | undefined, status?: string | null | undefined, accepted_at?: any | null | undefined }>, operator_shared_certificate_aggregate: { __typename?: 'operator_shared_certificate_aggregate', aggregate?: { __typename?: 'operator_shared_certificate_aggregate_fields', count: number } | null | undefined } };
export type GetRequestingCertificatesQueryVariables = Exact<{
  requesting_operator_id: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
  order_by_name?: InputMaybe<OrderBy>;
  order_by_skalNumber?: InputMaybe<OrderBy>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
}>;
export type GetRequestingCertificatesQuery = { __typename?: 'query_root', operator_shared_certificate: Array<{ __typename?: 'operator_shared_certificate', operator_certificate_inquiry_id?: any | null | undefined, requested_operator_location_name?: string | null | undefined, requested_operator_location_city?: string | null | undefined, requested_operator_location_postal_code?: any | null | undefined, requested_operator_location_street?: string | null | undefined, requested_operator_location_house_number?: string | null | undefined, requested_operator_skal_number?: string | null | undefined, valid_until?: any | null | undefined, certificate_number?: string | null | undefined, requesting_operator_id?: any | null | undefined, requested_operator_id?: any | null | undefined, status?: string | null | undefined, accepted_at?: any | null | undefined }>, operator_shared_certificate_aggregate: { __typename?: 'operator_shared_certificate_aggregate', aggregate?: { __typename?: 'operator_shared_certificate_aggregate_fields', count: number } | null | undefined } };
export type GetRequestOperatorCertificateOptionsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  search: Scalars['String'];
  sort: Scalars['String'];
  requestingOperatorId: Scalars['String'];
}>;
export type GetRequestOperatorCertificateOptionsQuery = { __typename?: 'query_root', getRequestOperatorCertificateOptions?: { __typename?: 'getRequestOperatorCertificateOptionsOutput', count?: number | null | undefined, requestOperatorCertificateOptions?: Array<{ __typename?: 'RequestOperatorCertificateOption', name: string, operatorCertificateInquiryId: string, skalNumber: string, operatorId: string, status: string, address: { __typename?: 'Address', city: string, country: string, houseNumber: string, postalCode: string, street: string } } | null | undefined> | null | undefined } | null | undefined };
export type GetOperatorIdentifierBySkalNumberQueryVariables = Exact<{
  skalNumber: Scalars['String'];
}>;
export type GetOperatorIdentifierBySkalNumberQuery = { __typename?: 'query_root', operator: Array<{ __typename?: 'operator', id: any }> };
export type GetNonConformiteitenSupersetTokenQueryVariables = Exact<{
  dashboardId: Scalars['String'];
  skalNumber: Scalars['String'];
}>;
export type GetNonConformiteitenSupersetTokenQuery = { __typename?: 'query_root', getNonConformiteitenSupersetToken?: { __typename?: 'SupersetAccessToken', accessToken: string } | null | undefined };
export type GetActiveCertificateBySkalNumberQueryVariables = Exact<{
  skalNumber: Scalars['String'];
}>;
export type GetActiveCertificateBySkalNumberQuery = { __typename?: 'query_root', certificate: Array<{ __typename?: 'certificate', id: any, validFrom: any, validUntil: any, tracesNumber: string, operator: { __typename?: 'operator', id: any, locations: Array<{ __typename?: 'location', id: any, name: string, tradeName?: string | null | undefined, street?: string | null | undefined, houseNumber?: string | null | undefined, postalCode?: any | null | undefined, city?: string | null | undefined, country?: string | null | undefined, isPrimary: boolean }> } }> };
export type GetAllOperatorsQueryVariables = Exact<{ [key: string]: never; }>;
export type GetAllOperatorsQuery = { __typename?: 'query_root', operator: Array<{ __typename?: 'operator', skalNumber: string, certificates: Array<{ __typename?: 'certificate', validFrom: any, validUntil: any }> }> };
export type GetOperatorBySkalNumberQueryVariables = Exact<{
  skalNumber: Scalars['String'];
}>;
export type GetOperatorBySkalNumberQuery = { __typename?: 'query_root', operator: Array<{ __typename?: 'operator', skalId: number, locations: Array<{ __typename?: 'location', id: any, tradeName?: string | null | undefined, isPrimary: boolean }> }> };
export const GetTracesCertificateDocument = gql`
    mutation getTracesCertificate($certificateId: String!) {
  getTracesCertificate(certificateId: $certificateId) {
    base64EncodedTracesCertificate
  }
}
    `;
export type GetTracesCertificateMutationFn = Apollo.MutationFunction<GetTracesCertificateMutation, GetTracesCertificateMutationVariables>;
export function useGetTracesCertificateMutation(baseOptions?: Apollo.MutationHookOptions<GetTracesCertificateMutation, GetTracesCertificateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetTracesCertificateMutation, GetTracesCertificateMutationVariables>(GetTracesCertificateDocument, options);
      }
export type GetTracesCertificateMutationHookResult = ReturnType<typeof useGetTracesCertificateMutation>;
export type GetTracesCertificateMutationResult = Apollo.MutationResult<GetTracesCertificateMutation>;
export type GetTracesCertificateMutationOptions = Apollo.BaseMutationOptions<GetTracesCertificateMutation, GetTracesCertificateMutationVariables>;
export const AcceptCertificateInquiryDocument = gql`
    mutation acceptCertificateInquiry($id: uuid!) {
  update_operator_certificate_inquiry(
    where: {id: {_eq: $id}, status: {_eq: "Requested"}}
    _set: {accepted_at: "now()", status: "Accepted"}
  ) {
    returning {
      id
    }
  }
}
    `;
export type AcceptCertificateInquiryMutationFn = Apollo.MutationFunction<AcceptCertificateInquiryMutation, AcceptCertificateInquiryMutationVariables>;
export function useAcceptCertificateInquiryMutation(baseOptions?: Apollo.MutationHookOptions<AcceptCertificateInquiryMutation, AcceptCertificateInquiryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptCertificateInquiryMutation, AcceptCertificateInquiryMutationVariables>(AcceptCertificateInquiryDocument, options);
      }
export type AcceptCertificateInquiryMutationHookResult = ReturnType<typeof useAcceptCertificateInquiryMutation>;
export type AcceptCertificateInquiryMutationResult = Apollo.MutationResult<AcceptCertificateInquiryMutation>;
export type AcceptCertificateInquiryMutationOptions = Apollo.BaseMutationOptions<AcceptCertificateInquiryMutation, AcceptCertificateInquiryMutationVariables>;
export const InviteOperatorDocument = gql`
    mutation InviteOperator($requestedOperatorId: String!, $requestingOperatorId: String!) {
  InviteOperator(
    requestedOperatorId: $requestedOperatorId
    requestingOperatorId: $requestingOperatorId
  ) {
    acceptedAt
    id
    requestedOperatorId
    requestingOperatorId
    status
  }
}
    `;
export type InviteOperatorMutationFn = Apollo.MutationFunction<InviteOperatorMutation, InviteOperatorMutationVariables>;
export function useInviteOperatorMutation(baseOptions?: Apollo.MutationHookOptions<InviteOperatorMutation, InviteOperatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteOperatorMutation, InviteOperatorMutationVariables>(InviteOperatorDocument, options);
      }
export type InviteOperatorMutationHookResult = ReturnType<typeof useInviteOperatorMutation>;
export type InviteOperatorMutationResult = Apollo.MutationResult<InviteOperatorMutation>;
export type InviteOperatorMutationOptions = Apollo.BaseMutationOptions<InviteOperatorMutation, InviteOperatorMutationVariables>;
export const RemoveCertificateInquiryDocument = gql`
    mutation removeCertificateInquiry($id: uuid!) {
  delete_operator_certificate_inquiry_by_pk(id: $id) {
    id
  }
}
    `;
export type RemoveCertificateInquiryMutationFn = Apollo.MutationFunction<RemoveCertificateInquiryMutation, RemoveCertificateInquiryMutationVariables>;
export function useRemoveCertificateInquiryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCertificateInquiryMutation, RemoveCertificateInquiryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCertificateInquiryMutation, RemoveCertificateInquiryMutationVariables>(RemoveCertificateInquiryDocument, options);
      }
export type RemoveCertificateInquiryMutationHookResult = ReturnType<typeof useRemoveCertificateInquiryMutation>;
export type RemoveCertificateInquiryMutationResult = Apollo.MutationResult<RemoveCertificateInquiryMutation>;
export type RemoveCertificateInquiryMutationOptions = Apollo.BaseMutationOptions<RemoveCertificateInquiryMutation, RemoveCertificateInquiryMutationVariables>;
export const ShareCertificateDocument = gql`
    mutation ShareCertificate($requestedOperatorId: uuid!, $requestingOperatorId: uuid!) {
  insert_operator_certificate_inquiry(
    objects: {requested_operator_id: $requestedOperatorId, requesting_operator_id: $requestingOperatorId, accepted_at: now, status: "Accepted"}
    on_conflict: {constraint: operator_certificate_inquiry_requesting_operator_id_requested_o, update_columns: [status, accepted_at]}
  ) {
    affected_rows
  }
}
    `;
export type ShareCertificateMutationFn = Apollo.MutationFunction<ShareCertificateMutation, ShareCertificateMutationVariables>;
export function useShareCertificateMutation(baseOptions?: Apollo.MutationHookOptions<ShareCertificateMutation, ShareCertificateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareCertificateMutation, ShareCertificateMutationVariables>(ShareCertificateDocument, options);
      }
export type ShareCertificateMutationHookResult = ReturnType<typeof useShareCertificateMutation>;
export type ShareCertificateMutationResult = Apollo.MutationResult<ShareCertificateMutation>;
export type ShareCertificateMutationOptions = Apollo.BaseMutationOptions<ShareCertificateMutation, ShareCertificateMutationVariables>;
export const GetInviteOperatorCertificateOptionsDocument = gql`
    query getInviteOperatorCertificateOptions($limit: Int!, $offset: Int!, $search: String!, $sort: String!, $requestedOperatorId: String!) {
  getInviteOperatorCertificateOptions(
    limit: $limit
    requestedOperatorId: $requestedOperatorId
    offset: $offset
    searchQueryString: $search
    sort: $sort
  ) {
    count
    inviteOperatorCertificateOptions {
      name
      operatorCertificateInquiryId
      skalNumber
      operatorId
      status
      address {
        city
        country
        houseNumber
        postalCode
        street
      }
    }
  }
}
    `;
export function useGetInviteOperatorCertificateOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetInviteOperatorCertificateOptionsQuery, GetInviteOperatorCertificateOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInviteOperatorCertificateOptionsQuery, GetInviteOperatorCertificateOptionsQueryVariables>(GetInviteOperatorCertificateOptionsDocument, options);
      }
export function useGetInviteOperatorCertificateOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInviteOperatorCertificateOptionsQuery, GetInviteOperatorCertificateOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInviteOperatorCertificateOptionsQuery, GetInviteOperatorCertificateOptionsQueryVariables>(GetInviteOperatorCertificateOptionsDocument, options);
        }
export type GetInviteOperatorCertificateOptionsQueryHookResult = ReturnType<typeof useGetInviteOperatorCertificateOptionsQuery>;
export type GetInviteOperatorCertificateOptionsLazyQueryHookResult = ReturnType<typeof useGetInviteOperatorCertificateOptionsLazyQuery>;
export type GetInviteOperatorCertificateOptionsQueryResult = Apollo.QueryResult<GetInviteOperatorCertificateOptionsQuery, GetInviteOperatorCertificateOptionsQueryVariables>;
export const GetPendingRequestedCertificateCountDocument = gql`
    query getPendingRequestedCertificateCount($requested_operator_id: uuid!) {
  operator_certificate_inquiry_aggregate(
    where: {requested_operator_id: {_eq: $requested_operator_id}, status: {_eq: "Requested"}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export function useGetPendingRequestedCertificateCountQuery(baseOptions: Apollo.QueryHookOptions<GetPendingRequestedCertificateCountQuery, GetPendingRequestedCertificateCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPendingRequestedCertificateCountQuery, GetPendingRequestedCertificateCountQueryVariables>(GetPendingRequestedCertificateCountDocument, options);
      }
export function useGetPendingRequestedCertificateCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPendingRequestedCertificateCountQuery, GetPendingRequestedCertificateCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPendingRequestedCertificateCountQuery, GetPendingRequestedCertificateCountQueryVariables>(GetPendingRequestedCertificateCountDocument, options);
        }
export type GetPendingRequestedCertificateCountQueryHookResult = ReturnType<typeof useGetPendingRequestedCertificateCountQuery>;
export type GetPendingRequestedCertificateCountLazyQueryHookResult = ReturnType<typeof useGetPendingRequestedCertificateCountLazyQuery>;
export type GetPendingRequestedCertificateCountQueryResult = Apollo.QueryResult<GetPendingRequestedCertificateCountQuery, GetPendingRequestedCertificateCountQueryVariables>;
export const GetRequestedCertificatesDocument = gql`
    query getRequestedCertificates($requested_operator_id: uuid!, $limit: Int, $order_by_name: order_by, $order_by_skalNumber: order_by, $offset: Int = 0, $search: String = "%%") {
  operator_shared_certificate(
    limit: $limit
    offset: $offset
    order_by: {status: desc, requesting_operator_location_name: $order_by_name, requesting_operator_skal_number: $order_by_skalNumber}
    where: {_and: [{requested_operator_id: {_eq: $requested_operator_id}}, {requested_operator_location_is_primary: {_eq: true}}, {requesting_operator_location_is_primary: {_eq: true}}, {valid_from: {_lte: "now()"}}, {valid_until: {_gte: "now()"}}, {_or: [{requesting_operator_location_name: {_ilike: $search}}, {requesting_operator_skal_number: {_ilike: $search}}]}]}
  ) {
    operator_certificate_inquiry_id
    requesting_operator_location_name
    requesting_operator_location_city
    requesting_operator_location_postal_code
    requesting_operator_location_street
    requesting_operator_location_house_number
    requesting_operator_skal_number
    valid_until
    certificate_number
    requesting_operator_id
    requested_operator_id
    status
    accepted_at
  }
  operator_shared_certificate_aggregate(
    where: {_and: [{requested_operator_id: {_eq: $requested_operator_id}}, {requested_operator_location_is_primary: {_eq: true}}, {requesting_operator_location_is_primary: {_eq: true}}, {valid_from: {_lte: "now()"}}, {valid_until: {_gte: "now()"}}, {_or: [{requesting_operator_location_name: {_ilike: $search}}, {requesting_operator_skal_number: {_ilike: $search}}]}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export function useGetRequestedCertificatesQuery(baseOptions: Apollo.QueryHookOptions<GetRequestedCertificatesQuery, GetRequestedCertificatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequestedCertificatesQuery, GetRequestedCertificatesQueryVariables>(GetRequestedCertificatesDocument, options);
      }
export function useGetRequestedCertificatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequestedCertificatesQuery, GetRequestedCertificatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequestedCertificatesQuery, GetRequestedCertificatesQueryVariables>(GetRequestedCertificatesDocument, options);
        }
export type GetRequestedCertificatesQueryHookResult = ReturnType<typeof useGetRequestedCertificatesQuery>;
export type GetRequestedCertificatesLazyQueryHookResult = ReturnType<typeof useGetRequestedCertificatesLazyQuery>;
export type GetRequestedCertificatesQueryResult = Apollo.QueryResult<GetRequestedCertificatesQuery, GetRequestedCertificatesQueryVariables>;
export const GetRequestingCertificatesDocument = gql`
    query getRequestingCertificates($requesting_operator_id: uuid!, $limit: Int, $order_by_name: order_by, $order_by_skalNumber: order_by, $offset: Int = 0, $search: String = "%%") {
  operator_shared_certificate(
    limit: $limit
    offset: $offset
    order_by: {requested_operator_location_name: $order_by_name, requested_operator_skal_number: $order_by_skalNumber}
    where: {_and: [{requesting_operator_id: {_eq: $requesting_operator_id}}, {requested_operator_location_is_primary: {_eq: true}}, {requesting_operator_location_is_primary: {_eq: true}}, {valid_from: {_lte: "now()"}}, {valid_until: {_gte: "now()"}}, {_or: [{requested_operator_location_name: {_ilike: $search}}, {requested_operator_skal_number: {_ilike: $search}}]}]}
  ) {
    operator_certificate_inquiry_id
    requested_operator_location_name
    requested_operator_location_city
    requested_operator_location_postal_code
    requested_operator_location_street
    requested_operator_location_house_number
    requested_operator_skal_number
    valid_until
    certificate_number
    requesting_operator_id
    requested_operator_id
    status
    accepted_at
  }
  operator_shared_certificate_aggregate(
    where: {_and: [{requesting_operator_id: {_eq: $requesting_operator_id}}, {requested_operator_location_is_primary: {_eq: true}}, {requesting_operator_location_is_primary: {_eq: true}}, {valid_from: {_lte: "now()"}}, {valid_until: {_gte: "now()"}}, {_or: [{requested_operator_location_name: {_ilike: $search}}, {requested_operator_skal_number: {_ilike: $search}}]}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export function useGetRequestingCertificatesQuery(baseOptions: Apollo.QueryHookOptions<GetRequestingCertificatesQuery, GetRequestingCertificatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequestingCertificatesQuery, GetRequestingCertificatesQueryVariables>(GetRequestingCertificatesDocument, options);
      }
export function useGetRequestingCertificatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequestingCertificatesQuery, GetRequestingCertificatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequestingCertificatesQuery, GetRequestingCertificatesQueryVariables>(GetRequestingCertificatesDocument, options);
        }
export type GetRequestingCertificatesQueryHookResult = ReturnType<typeof useGetRequestingCertificatesQuery>;
export type GetRequestingCertificatesLazyQueryHookResult = ReturnType<typeof useGetRequestingCertificatesLazyQuery>;
export type GetRequestingCertificatesQueryResult = Apollo.QueryResult<GetRequestingCertificatesQuery, GetRequestingCertificatesQueryVariables>;
export const GetRequestOperatorCertificateOptionsDocument = gql`
    query getRequestOperatorCertificateOptions($limit: Int!, $offset: Int!, $search: String!, $sort: String!, $requestingOperatorId: String!) {
  getRequestOperatorCertificateOptions(
    limit: $limit
    requestingOperatorId: $requestingOperatorId
    offset: $offset
    searchQueryString: $search
    sort: $sort
  ) {
    count
    requestOperatorCertificateOptions {
      name
      operatorCertificateInquiryId
      skalNumber
      operatorId
      status
      address {
        city
        country
        houseNumber
        postalCode
        street
      }
    }
  }
}
    `;
export function useGetRequestOperatorCertificateOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetRequestOperatorCertificateOptionsQuery, GetRequestOperatorCertificateOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequestOperatorCertificateOptionsQuery, GetRequestOperatorCertificateOptionsQueryVariables>(GetRequestOperatorCertificateOptionsDocument, options);
      }
export function useGetRequestOperatorCertificateOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequestOperatorCertificateOptionsQuery, GetRequestOperatorCertificateOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequestOperatorCertificateOptionsQuery, GetRequestOperatorCertificateOptionsQueryVariables>(GetRequestOperatorCertificateOptionsDocument, options);
        }
export type GetRequestOperatorCertificateOptionsQueryHookResult = ReturnType<typeof useGetRequestOperatorCertificateOptionsQuery>;
export type GetRequestOperatorCertificateOptionsLazyQueryHookResult = ReturnType<typeof useGetRequestOperatorCertificateOptionsLazyQuery>;
export type GetRequestOperatorCertificateOptionsQueryResult = Apollo.QueryResult<GetRequestOperatorCertificateOptionsQuery, GetRequestOperatorCertificateOptionsQueryVariables>;
export const GetOperatorIdentifierBySkalNumberDocument = gql`
    query getOperatorIdentifierBySkalNumber($skalNumber: String!) {
  operator(where: {skalNumber: {_eq: $skalNumber}}) {
    id
  }
}
    `;
export function useGetOperatorIdentifierBySkalNumberQuery(baseOptions: Apollo.QueryHookOptions<GetOperatorIdentifierBySkalNumberQuery, GetOperatorIdentifierBySkalNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOperatorIdentifierBySkalNumberQuery, GetOperatorIdentifierBySkalNumberQueryVariables>(GetOperatorIdentifierBySkalNumberDocument, options);
      }
export function useGetOperatorIdentifierBySkalNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOperatorIdentifierBySkalNumberQuery, GetOperatorIdentifierBySkalNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOperatorIdentifierBySkalNumberQuery, GetOperatorIdentifierBySkalNumberQueryVariables>(GetOperatorIdentifierBySkalNumberDocument, options);
        }
export type GetOperatorIdentifierBySkalNumberQueryHookResult = ReturnType<typeof useGetOperatorIdentifierBySkalNumberQuery>;
export type GetOperatorIdentifierBySkalNumberLazyQueryHookResult = ReturnType<typeof useGetOperatorIdentifierBySkalNumberLazyQuery>;
export type GetOperatorIdentifierBySkalNumberQueryResult = Apollo.QueryResult<GetOperatorIdentifierBySkalNumberQuery, GetOperatorIdentifierBySkalNumberQueryVariables>;
export const GetNonConformiteitenSupersetTokenDocument = gql`
    query GetNonConformiteitenSupersetToken($dashboardId: String!, $skalNumber: String!) {
  getNonConformiteitenSupersetToken(
    dashboardId: $dashboardId
    skalNumber: $skalNumber
  ) {
    accessToken
  }
}
    `;
export function useGetNonConformiteitenSupersetTokenQuery(baseOptions: Apollo.QueryHookOptions<GetNonConformiteitenSupersetTokenQuery, GetNonConformiteitenSupersetTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNonConformiteitenSupersetTokenQuery, GetNonConformiteitenSupersetTokenQueryVariables>(GetNonConformiteitenSupersetTokenDocument, options);
      }
export function useGetNonConformiteitenSupersetTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNonConformiteitenSupersetTokenQuery, GetNonConformiteitenSupersetTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNonConformiteitenSupersetTokenQuery, GetNonConformiteitenSupersetTokenQueryVariables>(GetNonConformiteitenSupersetTokenDocument, options);
        }
export type GetNonConformiteitenSupersetTokenQueryHookResult = ReturnType<typeof useGetNonConformiteitenSupersetTokenQuery>;
export type GetNonConformiteitenSupersetTokenLazyQueryHookResult = ReturnType<typeof useGetNonConformiteitenSupersetTokenLazyQuery>;
export type GetNonConformiteitenSupersetTokenQueryResult = Apollo.QueryResult<GetNonConformiteitenSupersetTokenQuery, GetNonConformiteitenSupersetTokenQueryVariables>;
export const GetActiveCertificateBySkalNumberDocument = gql`
    query getActiveCertificateBySkalNumber($skalNumber: String!) {
  certificate(
    where: {validFrom: {_lte: "now()"}, validUntil: {_gt: "now()"}, operator: {skalNumber: {_eq: $skalNumber}}}
  ) {
    id
    validFrom
    validUntil
    tracesNumber
    operator {
      id
      locations {
        id
        name
        tradeName
        street
        houseNumber
        postalCode
        city
        country
        isPrimary
      }
    }
  }
}
    `;
export function useGetActiveCertificateBySkalNumberQuery(baseOptions: Apollo.QueryHookOptions<GetActiveCertificateBySkalNumberQuery, GetActiveCertificateBySkalNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveCertificateBySkalNumberQuery, GetActiveCertificateBySkalNumberQueryVariables>(GetActiveCertificateBySkalNumberDocument, options);
      }
export function useGetActiveCertificateBySkalNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveCertificateBySkalNumberQuery, GetActiveCertificateBySkalNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveCertificateBySkalNumberQuery, GetActiveCertificateBySkalNumberQueryVariables>(GetActiveCertificateBySkalNumberDocument, options);
        }
export type GetActiveCertificateBySkalNumberQueryHookResult = ReturnType<typeof useGetActiveCertificateBySkalNumberQuery>;
export type GetActiveCertificateBySkalNumberLazyQueryHookResult = ReturnType<typeof useGetActiveCertificateBySkalNumberLazyQuery>;
export type GetActiveCertificateBySkalNumberQueryResult = Apollo.QueryResult<GetActiveCertificateBySkalNumberQuery, GetActiveCertificateBySkalNumberQueryVariables>;
export const GetAllOperatorsDocument = gql`
    query getAllOperators {
  operator {
    skalNumber
    certificates {
      validFrom
      validUntil
    }
  }
}
    `;
export function useGetAllOperatorsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllOperatorsQuery, GetAllOperatorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllOperatorsQuery, GetAllOperatorsQueryVariables>(GetAllOperatorsDocument, options);
      }
export function useGetAllOperatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOperatorsQuery, GetAllOperatorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllOperatorsQuery, GetAllOperatorsQueryVariables>(GetAllOperatorsDocument, options);
        }
export type GetAllOperatorsQueryHookResult = ReturnType<typeof useGetAllOperatorsQuery>;
export type GetAllOperatorsLazyQueryHookResult = ReturnType<typeof useGetAllOperatorsLazyQuery>;
export type GetAllOperatorsQueryResult = Apollo.QueryResult<GetAllOperatorsQuery, GetAllOperatorsQueryVariables>;
export const GetOperatorBySkalNumberDocument = gql`
    query getOperatorBySkalNumber($skalNumber: String!) {
  operator(where: {skalNumber: {_eq: $skalNumber}}) {
    skalId
    locations {
      id
      tradeName
      isPrimary
    }
  }
}
    `;
export function useGetOperatorBySkalNumberQuery(baseOptions: Apollo.QueryHookOptions<GetOperatorBySkalNumberQuery, GetOperatorBySkalNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOperatorBySkalNumberQuery, GetOperatorBySkalNumberQueryVariables>(GetOperatorBySkalNumberDocument, options);
      }
export function useGetOperatorBySkalNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOperatorBySkalNumberQuery, GetOperatorBySkalNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOperatorBySkalNumberQuery, GetOperatorBySkalNumberQueryVariables>(GetOperatorBySkalNumberDocument, options);
        }
export type GetOperatorBySkalNumberQueryHookResult = ReturnType<typeof useGetOperatorBySkalNumberQuery>;
export type GetOperatorBySkalNumberLazyQueryHookResult = ReturnType<typeof useGetOperatorBySkalNumberLazyQuery>;
export type GetOperatorBySkalNumberQueryResult = Apollo.QueryResult<GetOperatorBySkalNumberQuery, GetOperatorBySkalNumberQueryVariables>;
export const Operations = {
  Query: {
    getInviteOperatorCertificateOptions: 'getInviteOperatorCertificateOptions',
    getPendingRequestedCertificateCount: 'getPendingRequestedCertificateCount',
    getRequestedCertificates: 'getRequestedCertificates',
    getRequestingCertificates: 'getRequestingCertificates',
    getRequestOperatorCertificateOptions: 'getRequestOperatorCertificateOptions',
    getOperatorIdentifierBySkalNumber: 'getOperatorIdentifierBySkalNumber',
    GetNonConformiteitenSupersetToken: 'GetNonConformiteitenSupersetToken',
    getActiveCertificateBySkalNumber: 'getActiveCertificateBySkalNumber',
    getAllOperators: 'getAllOperators',
    getOperatorBySkalNumber: 'getOperatorBySkalNumber'
  },
  Mutation: {
    getTracesCertificate: 'getTracesCertificate',
    acceptCertificateInquiry: 'acceptCertificateInquiry',
    InviteOperator: 'InviteOperator',
    removeCertificateInquiry: 'removeCertificateInquiry',
    ShareCertificate: 'ShareCertificate'
  }
}